body {
  margin: 0;
  padding: 0;
  background-color: rgb(250, 250, 250);
  text-align: center;
  scroll-behavior: smooth;
  /* background-color: red; */
  overflow-x: hidden;
}
.top-bar{
  background-color: rgb(255, 255, 255);
}
#navigation-bar {
  /* max-height: 50px; */
  color: rgb(138, 136, 136);
  background-color: rgb(255, 255, 255);
}
#navigation-bar img.logo,
img.logo {
  height: 50px;
  width: auto;
}
.nav-link,a,
.nav-link:visited,a:visited {
  color: rgb(138, 136, 136);
  text-transform: capitalize;
  text-decoration: none;
}
.nav-link:hover,a:hover {
  color: blue;
  text-transform: capitalize;
  text-decoration: none;
}
#navigation-bar input#search {
  background-color: rgb(255, 255, 255);
  color: rgb(138, 136, 136);
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}
#navigation-bar input#search:focus {
  outline: none;
  /* border-bottom: 0.5px solid grey; */
}
#navigation-bar .menu {
  cursor: pointer;
}
#navigation-bar .menu:hover {
  color: blue;
}
.menu > span#cart-count {
  color: blue;
  /* margin-bottom: 2em; */
}
.feature {
  height: 500px;
  background-image: url(assets/feature-home.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.feature-mobile {
  height: 300px;
  background-image: url(assets/feature-home-mobile.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.category-card {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.card-img-top{
object-fit: cover;
height: 200px;
}
.clothing {
  background: url(assets/clothing.png);
  color: black;
}

.shoes {
  background: url(assets/shoes.png);
  color: black;
}

.accessories {
  background: url(assets/accessories.png);
  color: black;
}
.clothing:hover {
  background: linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.31) 0%,
      rgba(20, 20, 20, 0.31) 100%
    ),
    url(assets/clothing.png);
  color: white;
}

.shoes:hover {
  background: linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.31) 0%,
      rgba(20, 20, 20, 0.31) 100%
    ),
    url(assets/shoes.png);
  color: white;
}

.accessories:hover {
  background: linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.31) 0%,
      rgba(20, 20, 20, 0.31) 100%
    ),
    url(assets/accessories.png);
  color: white;
}
.bg-trending {
  background-image: url(assets/bg-trending.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-smoke{
  background-color: rgb(238, 238, 238);
}
.trending > img {
  /* width:500px; */
}
.product-image{
  width:360px;
  object-fit: cover;
}
.product-thumbnail{
  width: 80px;
  object-fit: contain;
  cursor: pointer;
}
.card-img-large {
  width: 250px;
  height:250px;
  object-fit: cover;
}
.card-img-small {
  width: 180px;
  height: 160px;
  object-fit: cover;
}
.card-head{
  overflow: hidden !important;
}
.card-body {
  background-color: rgb(246, 247, 248);
}
.footer a,
.footer a:hover,
.footer a:visited {
  text-decoration: none;
  color: rgb(138, 136, 136);
}
.footer a:hover {
  color: white;
}
.social-icons {
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background-color: white; */
  /* border-radius: 50%; */
  /* border: 1px solid black; */
}
#back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
#back-to-top > span.material-icons {
  cursor: pointer;
}
.btn {
  cursor: pointer !important;
}
.fs-0 {
  font-size: 10rem;
}
.slide{
  height:400px;

}
.slide img{
  height: 100%;
  max-height: 500px;
  object-fit: cover;
}